<template>
  <div class="team">
    <div class="container">
      <h3 class="agileits-title">产品价格（C端）</h3>
      <div class="team-row-agileinfo">
        <div class="col-md-3 col-xs-6 team-grids">
          <div class="thumbnail team-agileits">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/t1.jpg"
              alt=""
            />
            <div class="w3agile-caption">
              <h4>免费版(￥0)</h4>
              <p>智能专业定位系统</p>
              <div class="social-icon social-w3lsicon">
                <p>☆ 职业倾向报告</p>
                <p>☆ 人格倾向报告</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 team-grids">
          <div class="thumbnail team-agileits">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/t2.jpg"
              alt=""
            />
            <div class="w3agile-caption">
              <h4>VIP 版（￥299）</h4>
              <p>智能专业定位系统</p>
              <div class="social-icon social-w3lsicon">
                <p>☆ 免费版全部报告</p>
                <p>+ 创新素质指数报告</p>
                <p>+ 最匹配与最不匹配的大类专业</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 team-grids">
          <div class="thumbnail team-agileits">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/t3.jpg"
              alt=""
            />
            <div class="w3agile-caption">
              <h4>VIP 咨询版（￥1299）</h4>
              <p>智能专业定位系统</p>
              <div class="social-icon social-w3lsicon">
                <p>☆ 基础版全部报告</p>
                <p>+ 最匹配高校大类专业详细报告</p>
                <p>+ 最适合大类专业内小类专业排序报告</p>
                <p>+ 高校大类专业全匹配报告</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 team-grids">
          <div class="thumbnail team-agileits">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/t4.jpg"
              alt=""
            />
            <div class="w3agile-caption">
              <h4>普通版（￥199）</h4>
              <p>学科能力测评系统</p>
              <div class="social-icon social-w3lsicon">
                <p>☆ 9 个学科专业能力测评</p>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {},
  methods: {}
}
</script>

<style lang="less">
</style>
